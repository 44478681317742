import { filter } from "lodash";
import React, { type Dispatch, type SetStateAction, useState } from "react";
import "./Brands.scss";
import { Link } from "react-router-dom";
// @ts-expect-error
import icon_close from "../assets/icon_close.svg";
import { useCachedData } from "../hooks/useCachedData";
import ETypeOfPartnership from "../types/enum/type-of-partnerships";
import type { IBrand } from "../types/types";
import AnimatedKringel from "./AnimatedKringel";
import Brand from "./Brand";
import MultiMap from "./map/MultiMap";

type Props = {
	showAll?: boolean;
};

function Brands({ showAll = false }: Props) {
	const { brands, randomBrands, setFilterPartnersByBrand } = useCachedData();

	const [searchText, setSearchText] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const [brandName, setBrandName] = useState<string>("");

	const marken: IBrand[] = [];

	if (searchText.length === 0) {
		// all brands
		Object.keys(brands).map((key) => {
			return marken.push(brands[key]);
		});
	} else {
		// filter brands
		const filteredResults = filter(brands, (o) => {
			return o.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
		});
		filteredResults.map((brand) => marken.push(brand));
	}

	return (
		<>
			{!showAll ? (
				<div className="Marken AnimatedKringelParent" id="brands">
					{openModal && (
						<BrandModal brandName={brandName} setOpenModal={setOpenModal} />
					)}
					<AnimatedKringel
						text="Top-Marken"
						marginBottomMobile="-200px"
						everyNWordsMobile={5}
						wordOffsetMobile={3}
						pathVar={0}
					/>
					<div className="Marken-Content">
						<div className="Marken-group">
							{randomBrands.map((brand) => (
								<>
									<Brand
										key={`brand_${brand.id}`}
										name={brand.name}
										id={brand.id}
										logo={brand.logo}
										open={() => {
											setOpenModal(true);
											setBrandName(brand.name);
											setFilterPartnersByBrand(brand.id);
										}}
										imageVisibleByDefault={!showAll}
									/>
								</>
							))}
						</div>
						<div className="Marken-details row">
							<div className="col">
								{randomBrands.length} / {Object.keys(brands).length} Marken
							</div>
							<div className="col col-button">
								<Link to="/marken" className="button">
									Alle Marken ansehen
								</Link>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="Marken AnimatedKringelParent" id="brands">
					{openModal && (
						<BrandModal brandName={brandName} setOpenModal={setOpenModal} />
					)}
					<AnimatedKringel
						text="Top-Marken"
						// TODO this prop never existed in the first place
						// marginBottom={"-25%"}
					/>
					<div className="Marken-Content">
						<div className="row">
							<div className="col text-center">
								<input
									className="searchbox"
									type="text"
									name="searchText"
									placeholder="Nach Marke suchen..."
									value={searchText}
									onChange={(e) => setSearchText(e.target.value)}
								/>
							</div>
						</div>

						<div className="Marken-group">
							{marken.map((brand) => (
								<Brand
									key={`brand_${brand.id}`}
									name={brand.name}
									id={brand.id}
									logo={brand.logo}
									open={() => {
										setOpenModal(true);
										setBrandName(brand.name);
										setFilterPartnersByBrand(brand.id);
									}}
									imageVisibleByDefault={!showAll}
								/>
							))}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Brands;

function BrandModal({
	brandName,
	setOpenModal,
}: {
	brandName: string;
	setOpenModal: Dispatch<SetStateAction<boolean>>;
}) {
	const { filterPartnersByBrand: id, setFilterPartnersByBrand } =
		useCachedData();

	return (
		<div className="Brands-Modal">
			<div
				className="Brands-Modal-Closer"
				onClick={() => {
					setOpenModal(false);
					setFilterPartnersByBrand(undefined);
				}}
				onKeyDown={(e) => {
					e.key === "escape" && setOpenModal(false);
					setFilterPartnersByBrand(undefined);
				}}
			/>
			<div className="Brands-Modal-Content">
				<button
					className="Brands-Modal-Close"
					onClick={() => {
						setOpenModal(false);
						setFilterPartnersByBrand(undefined);
					}}
					type="button"
				>
					<img
						src={icon_close}
						width={24}
						height={24}
						alt="Fenster Schliessen"
					/>
				</button>
				{id ? (
					<MultiMap
						type={ETypeOfPartnership.haendler}
						hideBanner={true}
						headerText={`Radl-Händler mit ${brandName}`}
					/>
				) : null}
			</div>
		</div>
	);
}
