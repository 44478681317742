// @ts-expect-error
import { CookieBanner } from "@kubide.dev/react-cookie-law-full";
import { filter } from "lodash";
import React, { useEffect, useState, Suspense } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import { Outlet, useLoaderData, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./App.scss";
import { LoadScript } from "@react-google-maps/api";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import {
	GOOGLE_LIBRARIES,
	GOOGLE_MAPS_API_KEY,
} from "./components/map/MultiMap";
import { apiCMS, apiCall } from "./helpers/apiCall";
import generateRandomBrands from "./helpers/generateBrands";
import { getBaseUrl } from "./helpers/getBaseUrl";
import type { ContextType } from "./hooks/useCachedData";
import EEndpoints from "./types/enum/endpoints";
import ETypeOfPartnership from "./types/enum/type-of-partnerships";
import type {
	FirmenradlData,
	IFirm,
	IInsurance,
	ILeasingDuration,
	ILegalForm,
	IPartner,
	IResidualvalue,
} from "./types/types";

const tagManagerArgs = {
	gtmId: "GTM-5PSXW8Q",
};

// @todo: https://github.com/JustFly1984/react-google-maps-api/issues/70
// see: https://github.com/JustFly1984/react-google-maps-api/issues/159#issuecomment-502446663
class LoadScriptOnlyIfNeeded extends LoadScript {
	componentDidMount() {
		const cleaningUp = true;
		const isBrowser = typeof document !== "undefined"; // require('@react-google-maps/api/src/utils/isbrowser')
		const isAlreadyLoaded =
			window.google?.maps && document.querySelector("body.first-hit-completed"); // AJAX page loading system is adding this class the first time the app is loaded
		if (!isAlreadyLoaded && isBrowser) {
			// @ts-ignore
			if (window.google && !cleaningUp) {
				console.error("google api is already presented");
				return;
			}

			this.isCleaningUp().then(this.injectScript);
		}

		if (isAlreadyLoaded) {
			this.setState({ loaded: true });
		}
	}
}

export async function loader() {
	const baseUrl = getBaseUrl(true, "/data", false, true);

	const devUrl = "https://test-firmenradl.intersport.at"; // for dev
	const prodUrl = "https://firmenradl.at";

	const data = await Promise.all([
		apiCMS<FirmenradlData>(baseUrl),
		apiCall<IInsurance[]>(`${prodUrl}/rest/${EEndpoints.listInsurances}`),
		apiCall<ILeasingDuration[]>(
			`${prodUrl}/rest/${EEndpoints.listLeasingdurations}`,
		),
		apiCall<ILegalForm[]>(`${prodUrl}/rest/${EEndpoints.listLegalforms}`),
		apiCall<IResidualvalue[]>(
			`${prodUrl}/rest/${EEndpoints.listResidualvalues}`,
		),
	]);

	return data;
}

const App = () => {
	const data = useLoaderData() as [
		FirmenradlData,
		IInsurance[],
		ILeasingDuration[],
		ILegalForm[],
		IResidualvalue[],
	];
	const [base, insurances, leasingDurations, legalForms, residualValues] = data;

	const location = useLocation();

	const [marketing, setMarketing] = useState(false);
	const [marketingActivated, setMarketingActivated] = useState(false);
	const [filterPartnersByBrand, setFilterPartnersByBrand] = useState<
		IPartner["id"] | number | undefined
	>(undefined);

	const fullPage = !window.location.pathname.includes("/embed");

	const activatePreferences = () => {
		// console.info("ACTIVATE PREFERENCES");
	};
	const activateStatistics = () => {
		// console.info("ACTIVATE STATISTIC");
	};

	const randomBrands = generateRandomBrands(base.brands);
	const { firms, partners } = base;
	const mappedPartnerships: (IPartner | IFirm)[] = [];

	// biome-ignore lint/complexity/noForEach: <explanation>
	Object.keys(base.firms).forEach((key) => {
		const data = firms[key];
		data.type = ETypeOfPartnership.arbeitgeber;
		mappedPartnerships.push(data);
	});

	// biome-ignore lint/complexity/noForEach: <explanation>
	Object.keys(base.partners).forEach((key) => {
		const data = partners[key];
		data.type = ETypeOfPartnership.haendler;
		mappedPartnerships.push(data);
	});

	const filteredPartners = filterPartnersByBrand
		? filter(base.partners, (o) =>
				o.bikes.some((bike) => bike.id === filterPartnersByBrand),
			)
		: [];

	useEffect(() => {
		if (marketing && !marketingActivated) {
			TagManager.initialize(tagManagerArgs);
			setMarketingActivated(true);
		}
	}, [marketing, marketingActivated]);

	const className = location.pathname
		.replace("/", " ")
		.replace("/", " ")
		.replace(".", "_");

	return (
		<div className={`App ${className}`}>
			<ScrollToTop />

			<Helmet>
				<title>
					Das FIRMENRADL ist dein Radl - Marken & Modelle frei wählbar
				</title>
				<meta name="theme-color" content="#E0334C" />
			</Helmet>

			{fullPage && (
				<CookieBanner
					message="Wir setzen Cookies ein, um verschiedene Funktionen der auf dieser Website angebotenen Services zu gewährleisten, Zugriffe darauf zu analysieren und das Ergebnis zu deren Optimierung zu nutzen. Cookies werden von uns auch dazu verwendet, zielgerichtete Werbung zu ermöglichen. Daten werden zu diesem Zweck auch an verbundene Unternehmen und unsere Partner weitergegeben. Durch die Nutzung unserer Webseite stimmen Sie der Verwendung von Cookies zu. Mehr Informationen finden Sie in unserer Datenschutzerklärung und Cookie Policy"
					wholeDomain={true}
					onAccept={() => {}}
					onAcceptPreferences={() => {
						activatePreferences();
					}}
					onAcceptStatistics={() => {
						activateStatistics();
					}}
					onAcceptMarketing={() => {
						if (!marketing) {
							setTimeout(() => {
								setMarketing(true);
							}, 200);
						}
					}}
					privacyPolicyLinkText="Datenschutzerklärung"
					policyLink="/cms/datenschutzerklarung"
					acceptButtonText="Cookies akzeptieren"
					declineButtonText="Cookies ablehnen"
					showDeclineButton={true}
					managePreferencesButtonText="Privatsphäreeinstellungen bearbeiten"
					savePreferencesButtonText="Privatsphäreeinstellungen speichern"
					showStatisticsOption={false}
					necessaryOptionText="Notwendige"
					statisticsOptionText="Statistiken"
					marketingOptionText="Marketing"
					preferencesOptionText="Einstellungen"
					onDeclinePreferences={() => {}}
					onDeclineStatistics={() => {}}
					onDeclineMarketing={() => {}}
				/>
			)}

			{fullPage && <Header />}

			<LoadScriptOnlyIfNeeded
				googleMapsApiKey={GOOGLE_MAPS_API_KEY}
				libraries={GOOGLE_LIBRARIES}
			>
				<TransitionGroup>
					<CSSTransition key={location.key} classNames="fade" timeout={1000}>
						<Suspense fallback={<div className="suspenseFallback" />}>
							<Outlet
								context={
									{
										...base,
										randomBrands,
										filterPartnersByBrand,
										setFilterPartnersByBrand,
										filteredPartners,
										mappedPartnerships,
										insurances: insurances.filter((i) => i.Active), // die neuen Versicherungen // insurances
										leasingDurations: leasingDurations.filter((i) => i.Active), // die neuen Restwertvarianten // residual Values
										legalForms,
										residualValues: residualValues.filter((i) => i.Active), // die neuen Leasingvarianten // leasing
									} satisfies ContextType
								}
							/>
						</Suspense>
					</CSSTransition>
				</TransitionGroup>
			</LoadScriptOnlyIfNeeded>

			{fullPage && <Footer />}
		</div>
	);
};

export default App;
