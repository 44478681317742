import { arrayMoveImmutable } from "array-move";
import React, { type ReactNode } from "react";
import { useState } from "react";
import { Draggable } from "react-drag-reorder";
import { Helmet } from "react-helmet";
import Spacer from "../components/Spacer";

import "./Admin.scss";
import AdminFaq from "../adminComponents/AdminFaq";
import AdminPrivacy from "../adminComponents/AdminPrivacy";
import FAQ from "../components/FAQ";
import { getBaseUrl } from "../helpers/getBaseUrl";
import type { ICMS, IFAQEntry } from "../types/types";

/*
type TAdminResponse = {
	authenticated: boolean;
	faq: IFAQEntry[];
	cms: ICMS;
	error?: string;
};
*/

const ADMIN_PASSWORD = "ujijkldfg4985huikerujht34il3456kjbdsg";

/*export const loader = async () => {
	const password = sessionStorage.getItem("password") || "";
	const url = getBaseUrl(true, "/editStaticData", false, true);

	try {
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ password }),
		});

		const data = await response.json();

		if (data.authenticated) {
			return {
				authenticated: true,
				faq: data.faq,
				cms: data.cms,
			};
			// biome-ignore lint/style/noUselessElse: <explanation>
		} else {
			return {
				authenticated: false,
				faq: [],
				cms: { privacy: "" },
				error: "Authentication failed",
			};
		}
	} catch (error) {
		console.error("Error fetching data:", error);
		return {
			authenticated: false,
			faq: [],
			cms: { privacy: "" },
			error: "Failed to fetch data",
		};
	}
};*/

/*export const action = async ({ request }) => {
	const formData = await request.formData();
	const password = formData.get("password") as string;
	const faq = JSON.parse(formData.get("faq") as string);
	const cms = JSON.parse(formData.get("cms") as string);
	const update = formData.get("update") === "true";

	console.log("password: ", password);

	const url = getBaseUrl(true, "/editStaticData", false, true);

	try {
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ password, faq, update, cms }),
		});

		const data = await response.json();

		if (data.authenticated) {
			sessionStorage.setItem("password", password);
			return redirect("/admin");
			// biome-ignore lint/style/noUselessElse: <explanation>
		} else {
			return { error: "Authentication failed" };
		}
	} catch (error) {
		console.error("Error submitting data:", error);
		return { error: "Failed to submit data" };
	}
};*/

function Admin() {
	/*const {
		authenticated,
		faq: initialFaq,
		cms: initalCMS,
		error,
	} = useLoaderData() as TAdminResponse;*/

	const [password, setPassword] = useState(
		sessionStorage.getItem("password") ?? "",
	);
	const [authenticated, setAuthenticated] = useState(false);

	const [faq, setFaq] = useState<IFAQEntry[]>([]);
	const [cms, setCms] = useState<ICMS>({ privacy: "" });
	const [fetched, setFetched] = useState(false);
	const [feedbackMessage, setFeedbackMessage] = useState<string | undefined>();

	const [currentView, setCurrentView] = useState("reorder");

	// console.log("password", password, sessionStorage.getItem("password"));
	// console.log(cms);

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const refreshData = async (e: any, newFaq = false) => {
		if (e) {
			e.preventDefault();
		}
		const url = getBaseUrl(true, "/editStaticData", false, true);
		sessionStorage.setItem("password", password);

		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					password: password,
					faq: newFaq ? newFaq : faq,
					update: authenticated,
					cms: cms,
				}),
			});

			const data = await response.json();

			if (data.authenticated) {
				setAuthenticated(true);
				setFaq(data.faq);
				setCms(data.cms);
				sessionStorage.setItem("password", password);
				setFeedbackMessage("Erfolgreich gespeichert.");
				setTimeout(() => {
					setFeedbackMessage(undefined);
				}, 2000);
			}
		} catch (error) {
			console.error("Error:", error);
			// Handle error appropriately here
		}
	};

	const addNewFaq = () => {
		const newData = [...faq];
		newData.push({
			title: "",
			body: "",
			users: true,
			firms: true,
			partners: true,
			index: faq.length + 1,
		});
		setFaq(newData);
	};

	const onDeleteFaq = (index: number) => {
		const newData = [...faq];
		newData.splice(index, 1);
		setFaq(newData);
	};

	// @TODO what is this fuck!!!
	const onChangeFaq = (
		index: number,
		name: string,
		value: string | boolean,
	) => {
		const newData = [...faq];
		// @ts-expect-error
		newData[index][name] = value;
		setFaq(newData);
	};

	const onReorderFaq = (currentPos: number, newPos: number) => {
		const newFaq = arrayMoveImmutable(faq, currentPos, newPos);
		setFaq(newFaq);
		// @ts-expect-error
		refreshData(null, newFaq);
	};

	const faqRendered: ReactNode[] = [];
	faq.forEach((element, index) => {
		faqRendered.push(
			<AdminFaq
				key={`faq_${element.title}`}
				faq={element}
				index={index}
				onChange={(index, name, value) => onChangeFaq(index, name, value)}
				onDelete={(index) => onDeleteFaq(index)}
			/>,
		);
	});

	if (!fetched) {
		refreshData(null);
		setFetched(true);
	}

	return (
		<div>
			<Helmet>
				<title>Häufig gestellte Fragen | FAQ | FIRMENRADL</title>
				<meta
					name="description"
					content="Du bekommst dein gewünschtes Marken-Fahrrad, egal ob normales Radl oder E-Bike, bequem im Leasing direkt über deinen Arbeitgeber mittels Gehaltsabzug."
				/>
			</Helmet>
			<Spacer />

			{!authenticated ? (
				<div className="adminInterface login">
					<form onSubmit={(e) => refreshData(e)}>
						<h1>Admin Interface</h1>
						<div className="row">
							<input
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<button type={"submit"}>Login</button>
						</div>
					</form>
				</div>
			) : (
				<div className="adminInterface">
					<form onSubmit={(e) => refreshData(e)}>
						<h1>Admin Bereich</h1>

						<div className="tabs">
							<div
								className={`tab ${currentView === "reorder" && "active"}`}
								onClick={() => setCurrentView("reorder")}
								onKeyDown={(e) =>
									e.key === "space" && setCurrentView("reorder")
								}
							>
								FAQ anordnen
							</div>
							<div
								className={`tab ${currentView === "edit" && "active"}`}
								onClick={() => setCurrentView("edit")}
								onKeyDown={(e) => e.key === "space" && setCurrentView("edit")}
							>
								FAQ bearbeiten
							</div>
							<div
								className={`tab ${currentView === "preview" && "active"}`}
								onClick={() => setCurrentView("preview")}
								onKeyDown={(e) =>
									e.key === "space" && setCurrentView("preview")
								}
							>
								FAQ Vorschau
							</div>
							<div
								className={`tab ${currentView === "privacy" && "active"}`}
								onClick={() => setCurrentView("privacy")}
								onKeyDown={(e) =>
									e.key === "space" && setCurrentView("privacy")
								}
							>
								Datenschutz
							</div>
						</div>

						<br />
						<br />

						<div className="buttonbar">
							<div className="row">
								<button type={"submit"}>Speichern</button>
								<button type={"button"} onClick={(e) => refreshData(e)}>
									Aktualisieren
								</button>
								{feedbackMessage !== null && <span>{feedbackMessage}</span>}
							</div>
						</div>
					</form>

					{currentView === "reorder" && (
						<div>
							<Draggable onPosChange={onReorderFaq}>
								{faq.map((faqItem, idx) => {
									return (
										<div
											key={faqItem.index ? faqItem.index : `generated_${idx}`}
											className="flex-item draggable"
											draggable={true}
										>
											<div className="text">{faqItem.title}</div>

											{faqItem.users && (
												<div className="pill an">Arbeitnehmer</div>
											)}
											{faqItem.firms && (
												<div className="pill ag">Arbeitgeber</div>
											)}
											{faqItem.partners && (
												<div className="pill fh">Händler</div>
											)}
										</div>
									);
								})}
							</Draggable>
						</div>
					)}

					{currentView === "edit" && (
						<div>
							<button
								onClick={() => addNewFaq()}
								className={"button"}
								type={"button"}
							>
								Neue FAQ
							</button>
							<div className="faq-list">{faqRendered}</div>
							<button
								onClick={() => addNewFaq()}
								className={"button"}
								type={"button"}
							>
								Neue FAQ
							</button>
						</div>
					)}

					{currentView === "preview" && (
						<div>
							<FAQ />
						</div>
					)}

					{currentView === "privacy" && (
						<div>
							<AdminPrivacy
								content={cms.privacy}
								onChange={(value) => setCms({ ...cms, privacy: value })}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default Admin;
