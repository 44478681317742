import { ContentState, EditorState, convertToRaw } from "draft-js";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// @ts-ignore
import draftToHtml from "draftjs-to-html";
// @ts-ignore
import htmlToDraft from "html-to-draftjs";
import type { IFAQEntry } from "../types/types";

interface AdminFaqProps {
	faq: IFAQEntry;
	index: number;
	onChange: (index: number, field: string, value: string | boolean) => void;
	onDelete: (index: number) => void;
}

interface AdminFaqState {
	editorState: EditorState;
	collapsed: boolean;
}

class AdminFaq extends React.Component<AdminFaqProps, AdminFaqState> {
	constructor(props: AdminFaqProps) {
		super(props);

		const contentBlock = htmlToDraft(
			typeof props.faq.body === "string" ? props.faq.body : "",
		);

		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(
				contentBlock.contentBlocks,
			);
			const editorState = EditorState.createWithContent(contentState);
			this.state = {
				editorState,
				collapsed: true,
			};
		}
	}

	onEditorStateChange(editorState: EditorState) {
		this.setState({
			editorState,
		});

		this.props.onChange(
			this.props.index,
			"body",
			draftToHtml(convertToRaw(editorState.getCurrentContent())),
		);
	}
	render() {
		if (this.state.collapsed) {
			return (
				<div
					key={`faq_${this.props.index}`}
					className={"admin-faq collapsed"}
					onClick={() => {
						this.setState({ collapsed: false });
					}}
					onKeyDown={(e) =>
						e.key === "space" && this.setState({ collapsed: false })
					}
				>
					{this.props.faq.title}
				</div>
			);
		}

		return (
			<div key={`faq_${this.props.index}`} className={"admin-faq"}>
				<div className="group">
					<div className="group">
						<label htmlFor={`users_${this.props.index}`}>Arbeitnehmer</label>
						<input
							type="checkbox"
							id={`users_${this.props.index}`}
							name={`users_${this.props.index}`}
							checked={!!this.props.faq.users}
							onChange={() =>
								this.props.onChange(
									this.props.index,
									"users",
									!this.props.faq.users,
								)
							}
						/>
					</div>
					<div className="group">
						<label htmlFor={`firms_${this.props.index}`}>Arbeitgeber</label>
						<input
							type="checkbox"
							id={`firms_${this.props.index}`}
							name={`firms_${this.props.index}`}
							checked={!!this.props.faq.firms}
							onChange={() =>
								this.props.onChange(
									this.props.index,
									"firms",
									!this.props.faq.firms,
								)
							}
						/>
					</div>
					<div className="group">
						<label htmlFor={`partners_${this.props.index}`}>Händler</label>
						<input
							type="checkbox"
							id={`partners_${this.props.index}`}
							name={`partners_${this.props.index}`}
							checked={!!this.props.faq.partners}
							onChange={() =>
								this.props.onChange(
									this.props.index,
									"partners",
									!this.props.faq.partners,
								)
							}
						/>
					</div>

					<div className="group">
						<button
							onClick={() => this.setState({ collapsed: true })}
							type={"button"}
						>
							Einklappen
						</button>
						<button
							onClick={() => this.props.onDelete(this.props.index)}
							type={"button"}
						>
							Löschen
						</button>
					</div>
				</div>
				<div className="group">
					<label htmlFor={`title_${this.props.index}`}>Titel</label>
					<input
						type="text"
						id={`title_${this.props.index}`}
						name={`title_${this.props.index}`}
						value={this.props.faq.title}
						onChange={(e) =>
							this.props.onChange(this.props.index, "title", e.target.value)
						}
					/>
					<br />
					<label htmlFor={`body_${this.props.index}`}>Beschreibung</label>
					<Editor
						editorState={this.state.editorState}
						toolbarClassName="toolbarClassName"
						wrapperClassName="wrapperClassName"
						editorClassName="editorClassName"
						toolbar={{
							options: ["inline", "blockType", "list", "link", "history"],
							inline: {
								inDropdown: false,
								options: ["bold", "italic", "underline", "strikethrough"],
							},
							blockType: {
								inDropdown: false,
								options: ["Normal", "H3", "H4", "H5", "H6", "Blockquote"],
							},
						}}
						onEditorStateChange={(editorState) =>
							this.onEditorStateChange(editorState)
						}
					/>
				</div>
			</div>
		);
	}
}

export default AdminFaq;
